import classNames from "classnames"
import React, { useEffect } from "react"

export type ModalProps = {
  title: React.ReactNode
  isDOMVisible: boolean
  isOpen: boolean
  onClose: () => void
  className?: string
  children: React.ReactNode
}

export const Modal = ({ isDOMVisible, isOpen, onClose, className, children, title }: ModalProps) => {
  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "unset"

    return () => {
      document.body.style.overflow = "unset"
    }
  }, [isOpen])

  if (!isOpen && !isDOMVisible) return null

  return (
    <>
      <div
        className={classNames("fixed inset-0 z-50 bg-black opacity-50", !isOpen && isDOMVisible && "hidden")}
        onClick={onClose}
      />
      <div
        className={classNames(
          "fixed inset-0 z-50 overflow-y-auto md:left-1/2 md:w-max md:-translate-x-1/2 md:overflow-y-hidden",
          !isOpen && isDOMVisible && "hidden",
        )}>
        <div className="flex min-h-full items-center justify-center pt-14 md:p-4">
          <div className={classNames("relative w-full max-w-4xl rounded-lg bg-white shadow-xl", className)}>
            <div className="sticky top-0 z-10 flex max-w-full items-start justify-between gap-2 rounded-t-lg bg-white p-6 md:static">
              {title}
              <button
                onClick={onClose}
                className="flex h-8 w-8 items-center justify-center rounded-full bg-white shadow-md hover:shadow-xl"
                aria-label="Close modal">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15 5L5 15M5 5L15 15"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>

            <div className="px-6 pb-26 md:max-h-[calc(100vh-8rem)] md:overflow-y-auto md:pb-6">{children}</div>
          </div>
        </div>
      </div>
    </>
  )
}
