import classNames from "classnames"
import React from "react"

import StarFilledIcon from "@bounce/assets/icons/Star-filled.svg"
import StarHalfIcon from "@bounce/assets/icons/Star-half.svg"
import StarIcon from "@bounce/assets/icons/Star.svg"

type StarProps = {
  fill: boolean
  half: boolean
  className?: string
}

export type RatingStarsProps = {
  ratingScore: number
  max?: number
  className?: string
  starClassName?: string
}

const Star = ({ fill, half, className }: StarProps) => {
  if (fill) {
    return <StarFilledIcon className={className} />
  }

  if (half) {
    return <StarHalfIcon className={className} />
  }

  return <StarIcon className={className} />
}

export const RatingStars = ({ ratingScore, max = 5, className, starClassName }: RatingStarsProps) => {
  const starsComponents: React.JSX.Element[] = []

  for (let i = 0; i < max; i++) {
    const fill = i < Math.floor(ratingScore)
    const half = i < ratingScore

    starsComponents.push(
      <Star key={i} fill={fill} half={half} className={classNames(starClassName, "rating-stars__star")} />,
    )
  }

  return <div className={classNames(className, "rating-stars")}>{starsComponents}</div>
}
