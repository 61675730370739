import classNames from "classnames"

import { SliderNavigationButton } from "../SliderNavigationButton/SliderNavigationButton"

export type SliderNavigationProps = {
  onClickPrev?: () => void
  onClickNext?: () => void
  className?: string
}

export const SliderNavigation = ({ className, onClickPrev, onClickNext }: SliderNavigationProps) => (
  <div className={classNames("slider__navigation", className)}>
    <SliderNavigationButton onClick={onClickPrev} direction="previous" />
    <SliderNavigationButton onClick={onClickNext} direction="next" />
  </div>
)
