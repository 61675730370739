import { useRouter } from "next/router"
import { useEffect } from "react"
import { useInView } from "react-intersection-observer"

import { Analytics } from "@bounce/analytics"

import { InteractionEvents } from "../libs/analytics/events"

type UseSectionViewedOptions = {
  name: string
  threshold?: number
  meta?: object
}

/**
 * Track section viewed using IntersectionObserver
 * @example
 * const { sectionRef } = useSectionViewed({ name: "SectionName" })
 *
 * return <section ref={sectionRef}>{children}</section>
 */
export const useSectionViewed = ({ name, threshold = 0.2, meta = {} }: UseSectionViewedOptions) => {
  const router = useRouter()

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold,
  })

  useEffect(() => {
    if (!inView) return

    Analytics.track(InteractionEvents.SectionViewed, {
      name,
      pagePath: router.asPath,
      pageCategory: "Marketing",
      pageName: router.route,
      ...meta,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  return { sectionRef: ref }
}
