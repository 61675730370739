import classNames from "classnames"
import * as React from "react"

import { stringToAvatar } from "@bounce/util"

import type { PNImageProps } from "../PNImage/PNImage"
import { PNImage } from "../PNImage/PNImage"

export type AvatarProps = {
  name: string
  className?: string
  textClassName?: string
  src?: PNImageProps["src"]
  alt?: string
  loading?: "lazy" | "eager" | undefined
}

export const Avatar = ({ name, className, textClassName, src, alt = "", loading = "lazy" }: AvatarProps) => {
  const { label, color, backgroundColor } = stringToAvatar(name)

  return (
    // IMPROVEMENT: use tailwind css jit for the color and the backgroundColor
    <div className={classNames(styles.baseView, className)} style={{ backgroundColor }}>
      {src ? (
        <PNImage
          src={src}
          alt={alt}
          width={36}
          height={36}
          loading={loading}
          className="h-full w-full"
          data-chromatic="ignore"
        />
      ) : (
        <span className={classNames(styles.baseText, textClassName)} style={{ color }}>
          {label}
        </span>
      )}
    </div>
  )
}

const styles = {
  baseView: "items-center justify-center rounded-full w-9 h-9 flex shrink-0 overflow-hidden",
  baseText: "text-xl font-normal text-center",
  baseImage: "rounded-full w-full h-full",
}
