export const capitalizeString = (text: string): string => {
  if (text.length === 0) {
    return ""
  }

  return `${text[0]?.toLocaleUpperCase() || ""}${text.slice(1)}`
}

export const titleCase = (text: string) => {
  return text
    .toLowerCase()
    .split(" ")
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ")
}

export const trimText = (text: string, length: number) => {
  if (text.length <= length) {
    return text
  }

  return text.substring(0, length).padEnd(length + 3, ".")
}

export function toSentenceCase(str: string) {
  return (str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()).replace("_", " ")
}

export const splitFullName = (fullName: string) => {
  const duplicateSpaceExp = / +(?= )/g
  const sanitizedFullName = fullName.trim().replace(duplicateSpaceExp, "")

  const [firstWord = "", ...otherWords] = sanitizedFullName.split(" ")

  return {
    firstName: firstWord,
    lastName: otherWords.join(" "),
    fullName: sanitizedFullName,
  }
}

export const getHashOfString = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    // tslint:disable-next-line: no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  hash = Math.abs(hash)
  return hash
}

/**
 * @example getFullName({ firstName: "terry", lastName: "davis" }) // "Terry Davis"
 */
export const getFullName = (user?: { firstName?: string | null; lastName?: string | null } | null) => {
  return titleCase(`${user?.firstName || ""} ${user?.lastName || ""}`).trim()
}

/**
 * @example
 * isString(45) // false
 * isString("cake") // true
 */
export const isString = (value: unknown): value is string => typeof value === "string"

/**
 * @example
 * stringOrNull(45) // null
 * stringOrNull("cake") // "cake"
 */
export const stringOrNull = (value: unknown): string | null => (isString(value) ? value : null)

export const isSlug = (value: unknown): value is string => {
  return isString(value) && /^[a-z0-9-]+$/.test(value)
}

/**
 * @example
 * countryCodeToEmoji("FR") // "🇫🇷"
 */
export const countryCodeToEmoji = (countryCode: string) => {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map(char => 127397 + char.charCodeAt(0))

  return String.fromCodePoint(...codePoints)
}
