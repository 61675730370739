import { getHashOfString } from "./string"

export function hex2Rgba(hex: string, alpha: number) {
  const r = parseInt(hex.substring(1, 3), 16)
  const g = parseInt(hex.substring(3, 5), 16)
  const b = parseInt(hex.substring(5, 7), 16)
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

const normalizeHash = (hash: number, min: number, max: number) => {
  return Math.floor((hash % (max - min)) + min)
}

/**
 * Generate hsl color from a string
 * @example
 * stringToColor("John doe", [40, 60], [60, 80]) //  hsl(0, 50%, 50%)
 */
export const stringToColor = (name: string, saturationRange: [number, number], lightnessRange: [number, number]) => {
  const hash = getHashOfString(name)
  const h = normalizeHash(hash, 0, 360)
  const s = normalizeHash(hash, saturationRange[0], saturationRange[1])
  const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1])

  return `hsl(${h},${s}%,${l}%)`
}

/**
 * Convert hsl to hsla
 * @example
 * hslToHsla("hsl(0, 100%, 50%)", 0.3) // hsla(0, 100%, 50%, 0.3)
 */
export function hslToHsla(hsl: string, alpha: number) {
  const hsla = hsl.replace("hsl", "hsla").slice(0, -1) + `,${alpha})`

  return hsla
}
