import classNames from "classnames"
import React from "react"

import ArrowLeftIcon from "@bounce/assets/icons/ArrowLeft.svg"
import ArrowRightIcon from "@bounce/assets/icons/ArrowRight.svg"

export type SliderNavigationButtonProps = {
  onClick?: () => void
  direction: "previous" | "next"
  className?: string
}

export const SliderNavigationButton = ({ onClick, direction, className }: SliderNavigationButtonProps) => {
  const Icon = direction === "previous" ? ArrowLeftIcon : ArrowRightIcon

  return (
    <button aria-label={direction} className={classNames("slider__navigation-btn", className)} onClick={onClick}>
      <Icon className="slider__navigation-btn-icon" />
    </button>
  )
}
