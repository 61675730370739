import { hslToHsla, stringToColor } from "./style"

const SATURATION_RANGE: [number, number] = [40, 60]
const LIGHTNESS_RANGE: [number, number] = [60, 80]

/**
 * Generate the Avatar component props
 * @example
 * stringToAvatar("John doe") // { color: "hsl(0, 50%, 50%)", backgroundColor: "hsla(0, 50%, 50%, 0.2)", label: "J" }
 */
export const stringToAvatar = (name: string) => {
  const color = stringToColor(name, SATURATION_RANGE, LIGHTNESS_RANGE)
  const backgroundColor = hslToHsla(color, 0.2)

  const firstLetter = name.trim().toUpperCase().charAt(0)
  const label = firstLetter

  const avatarProps = {
    color,
    backgroundColor,
    label,
  }

  return avatarProps
}
