/* eslint-disable tailwindcss/no-custom-classname */
import classNames from "classnames"
import React from "react"

import type { ReviewMetaDataProps } from "@bounce/web-components"
import { Avatar, RatingStars } from "@bounce/web-components"

export type ReviewProps = {
  feedback?: string | React.ReactNode
  className?: string
} & ReviewMetaDataProps

export const Review = ({
  feedback,
  className,
  rating,
  avatarAlt,
  avatarClassName,
  avatarSrc,
  metaData,
  name,
  withAvatar = true,
}: ReviewProps) => (
  <div className={classNames("review", className)}>
    <RatingStars ratingScore={rating} max={5} className="mb-0" starClassName="size-6 text-orange-700" />
    {!!feedback && <p className="review__feedback">{feedback}</p>}
    <div className="mt-auto">
      <div className={classNames("review__footer", avatarClassName)}>
        {withAvatar && (
          <Avatar
            name={name ?? ""}
            src={avatarSrc}
            alt={avatarAlt}
            loading="lazy"
            className={classNames("!size-16 rotate-[-5.71deg] rounded-none border-4 border-[#F9EDD8]", avatarClassName)}
          />
        )}
        <div className="flex flex-col justify-center">
          <span className="label1">{name}</span>
          {!!metaData && (
            <span className="review__metadata" data-chromatic="ignore" suppressHydrationWarning>
              {metaData}
            </span>
          )}
        </div>
      </div>
    </div>
  </div>
)
