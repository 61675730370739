import { DateTime } from "@bounce/date"
import { useTranslation } from "@bounce/i18n"

import { Product } from "../../../../types"
import type { Review } from "../../../../utils/reviews"

import user1 from "./assets/user-1.png"
import user10 from "./assets/user-10.png"
import user11 from "./assets/user-11.png"
import user12 from "./assets/user-12.png"
import user13 from "./assets/user-13.png"
import user14 from "./assets/user-14.png"
import user15 from "./assets/user-15.png"
import user16 from "./assets/user-16.png"
import user17 from "./assets/user-17.png"
import user2 from "./assets/user-2.png"
import user3 from "./assets/user-3.png"
import user4 from "./assets/user-4.png"
import user5 from "./assets/user-5.png"
import user6 from "./assets/user-6.png"
import user7 from "./assets/user-7.png"
import user8 from "./assets/user-8.png"
import user9 from "./assets/user-9.png"

const reviewUsersWithImages = [
  { fullName: "Christina R.", image: user1 },
  { fullName: "Becky M.", image: user2 },
  { fullName: "Milad M.", image: user3 },
  { fullName: "Cailyn W.", image: user4 },
  { fullName: "Deborah H.", image: user5 },
  { fullName: "Dragan V.", image: user6 },
  { fullName: "Melky K.", image: user8 },
  { fullName: "Purple V.", image: user7 },
  { fullName: "California R.", image: user11 },
  { fullName: "Bella M.", image: user9 },
  { fullName: "Daniela M.", image: user10 },
  { fullName: "Roxie L.", image: user12 },
]

const mockCompletedAt = (days: number) => DateTime.now().minus({ days }).toISO()

const mockReview = (review: Partial<Review>): Review => ({
  id: "83c75cdc-c99c-437e-b173-a538bdf8f884",
  user: {
    fullName: "Chelsea A Garza",
  },
  city: {
    id: "de1aed0b-d6a6-4330-84ae-28c1d3c2d5cc",
    translatedName: "New York",
    slug: "new-york",
  },
  feedback: "Held our luggage all day and everything was just as we left it!",
  translatedFeedback: null,
  rating: 5,
  completedAt: "2021-08-27T19:18:36.164385Z",
  ...review,
})

type UseReviewsProps = {
  product?: Product
  showAppReviews?: boolean
}

export const useReviews = ({ product, showAppReviews }: UseReviewsProps): Review[] => {
  const [t] = useTranslation()
  const usaTranslated = t("hooks.useReview.country.usa", "United States")

  if (product === Product.PackageAcceptance) {
    return [
      mockReview({
        id: "83c75cdc-c99c-437e-b173-a538bdf8f8807",
        user: { fullName: "Wade W.", image: user13 },
        city: {
          translatedName: "San Francisco, USA",
          slug: "san-francisco",
          id: "a2566946-df74-4f4f-87c1-2889ce855293",
        },
        feedback:
          "Loved this service and the guys working in the shop were super friendly. We really liked working with them.",
        rating: 5,
        completedAt: mockCompletedAt(1),
      }),
      mockReview({
        id: "83c75cdc-c99c-437e-b173-a538bdf8f8808",
        user: { fullName: "Ralph E.", image: user14 },
        city: {
          translatedName: "NYC, USA",
          slug: "new-york",
          id: "a2566946-df74-4f4f-87c1-2889ce855294",
        },
        feedback:
          "They were really helpful. Kept the store open for an extra half an hour because my bus into New York was running late. Very friendly and reliable.",
        rating: 5,
        completedAt: mockCompletedAt(3),
      }),
      mockReview({
        id: "83c75cdc-c99c-437e-b173-a538bdf8f8809",
        user: { fullName: "Kristin W.", image: user15 },
        city: {
          translatedName: "NYC, USA",
          slug: "new-york",
          id: "a2566946-df74-4f4f-87c1-2889ce855295",
        },
        feedback:
          "Never heard of this before but extremely helpful and cheaper than other options. Will definitely use again if the need arises.",
        rating: 5,
        completedAt: mockCompletedAt(5),
      }),
      mockReview({
        id: "83c75cdc-c99c-437e-b173-a538bdf8f8810",
        user: { fullName: "Bella Margolles", image: user16 },
        city: {
          translatedName: "Miami, USA",
          slug: "miami",
          id: "a2566946-df74-4f4f-87c1-2889ce855296",
        },
        feedback: "Excelente ubicación y buena atención. A 2 cuadras de la estación Port Authority, fácil de llegar.",
        rating: 5,
        completedAt: mockCompletedAt(7),
      }),
      mockReview({
        id: "83c75cdc-c99c-437e-b173-a538bdf8f8811",
        user: { fullName: "春木", image: user17 },
        city: {
          translatedName: "Tokyo, Japan",
          slug: "tokyo",
          id: "a2566946-df74-4f4f-87c1-2889ce855297",
        },
        feedback: "とても柔軟に対応してくれました、快適でした。",
        rating: 5,
        completedAt: mockCompletedAt(9),
      }),
    ]
  }

  if (showAppReviews) {
    return [
      mockReview({
        id: "83c75cdc-c99c-437e-b173-a538bdf8f8801",
        user: reviewUsersWithImages[6],
        country: usaTranslated,
        feedback: t(
          "hooks.review7.feedback",
          "Just found this app and the location in downtown Savannah just the day before. Super quick and easy way to store your luggage as we toured the city for a few hours.  Highly recommended!",
        ),
        rating: 5,
        completedAt: mockCompletedAt(1),
      }),
      mockReview({
        id: "83c75cdc-c99c-437e-b173-a538bdf8f8802",
        user: reviewUsersWithImages[7],
        country: usaTranslated,
        feedback: t(
          "hooks.review8.feedback",
          "I had never even heard of Bounce until we realized that we had a 9 hour layover in Las Vegas. I pulled up the app and made our reservation. The location was ready for us when we arrived. They had a separate locked secure place for our bags and bag tags to make sure we got the right ones when we picked them up! Will definitely be using the app for future travel - it made exploring a new city much easier!",
        ),
        rating: 5,
        completedAt: mockCompletedAt(3),
      }),
      mockReview({
        id: "83c75cdc-c99c-437e-b173-a538bdf8f8803",
        user: reviewUsersWithImages[8],
        country: t("hooks.review9.country", "Netherlands"),
        feedback: t(
          "hooks.review9.feedback",
          "Super easy and convenient! We downloaded the app, found a place dropped the bags and then we able to enjoy the pleasures of the red light district. No bags made it easy to pop into a coffee shop and then a little shopping window.",
        ),
        rating: 5,
        completedAt: mockCompletedAt(3),
      }),
      mockReview({
        id: "83c75cdc-c99c-437e-b173-a538bdf8f8804",
        user: reviewUsersWithImages[9],
        country: t("hooks.review10.country", "Canada"),
        feedback: t(
          "hooks.review10.feedback",
          "Delightful, easy to use app that saved our lives when travelling in Montreal and Quebec city! We didn’t realize that our airbnb check in times were so late compared to when we’d arrive, so it was great to easily book storage and go explore the city first. I loved that it was so easy to book at the last minute. ",
        ),
        rating: 5,
        completedAt: mockCompletedAt(4),
      }),
      mockReview({
        id: "83c75cdc-c99c-437e-b173-a538bdf8f8805",
        user: reviewUsersWithImages[10],
        country: t("hooks.review11.country", "Spain"),
        feedback: t(
          "hooks.review11.feedback",
          "App is easy to use! It took less than 10 minutes for me to find the app online, download it, and book the storage space. Smooth and easy process and transaction! Highly recommend.",
        ),
        rating: 5,
        completedAt: mockCompletedAt(4),
      }),
      mockReview({
        id: "83c75cdc-c99c-437e-b173-a538bdf8f8806",
        user: reviewUsersWithImages[11],
        country: t("hooks.review12.country", "United Kingdom"),
        feedback: t(
          "hooks.review12.feedback",
          "Great App for luggage storage - simple, affordable and easy! Use storage facility near Paddington station for 2 days, and have already recommended to others.",
        ),
        rating: 5,
        completedAt: mockCompletedAt(4),
      }),
    ]
  }

  return [
    mockReview({
      id: "83c75cdc-c99c-437e-b173-a538bdf8f8841",
      user: reviewUsersWithImages[0],
      city: {
        id: "de1aed0b-d6a6-4330-84ae-28c1d3c2d5cc",
        translatedName: t("hooks.review1.city", "New York"),
        slug: "new-york",
      },
      feedback: t(
        "hooks.review1.feedback",
        "Good experience. My bag was safe in a luggage room. Very nice people working in this hotel. ",
      ),
      rating: 5,
      completedAt: mockCompletedAt(1),
      country: "United States",
    }),
    mockReview({
      id: "83c75cdc-c99c-437e-b173-a538bdf8f8842",
      user: reviewUsersWithImages[1],
      city: {
        id: "a2566946-df74-4f4f-87c1-2889ce813293",
        translatedName: t("hooks.review2.city", "London"),
        slug: "london",
      },
      feedback: t(
        "hooks.review2.feedback2",
        "Smooth check in and check out of our luggage and super friendly people! Very easy and completely felt at ease my luggage was safe",
      ),
      rating: 5,
      completedAt: mockCompletedAt(3),
      country: "United Kingdom",
    }),
    mockReview({
      id: "83c75cdc-c99c-437e-b173-a538bdf8f8843",
      user: { fullName: "Milad M.", image: user3 },
      city: {
        id: "07284bf5-8390-46b3-8ba6-62a29d69b999",
        translatedName: t("hooks.review3.city", "Paris"),
        slug: "paris",
      },
      feedback: t(
        "hooks.review3.feedback",
        "Very easy to use. Took less than 5 mins for drop off and pick up. Everything was there, safe and secure!",
      ),
      rating: 5,
      completedAt: mockCompletedAt(3),
      country: "France",
    }),
    mockReview({
      id: "83c75cdc-c99c-437e-b173-a538bdf8f8844",
      user: reviewUsersWithImages[3],
      city: {
        id: "fe67646e-a4a4-40cd-a998-fc40d0c1b254",
        translatedName: t("hooks.review4.city", "Los Angeles"),
        slug: "los-angeles",
      },
      feedback: t(
        "hooks.review4.feedback",
        "Very easy and convenient way to store luggage. The staff were friendly and efficient. Thank you!",
      ),
      rating: 5,

      completedAt: mockCompletedAt(4),
      country: "United States",
    }),
    mockReview({
      id: "83c75cdc-c99c-437e-b173-a538bdf8f8845",
      user: reviewUsersWithImages[4],
      city: {
        id: "cd4b1a0d-0eef-45c7-bf80-b907c2fdd922",
        translatedName: t("hooks.review5.city", "Rome"),
        slug: "rome",
      },
      feedback: t(
        "hooks.review5.feedback",
        "Easy to find, very affordable, friendly staff and secure location. Would absolutely recommend.",
      ),
      rating: 5,
      completedAt: mockCompletedAt(5),
      country: "Italy",
    }),
    mockReview({
      id: "83c75cdc-c99c-437e-b173-a538bdf8f8846",
      user: reviewUsersWithImages[5],
      city: {
        id: "a58d2085-e884-4682-8971-9b5f1f6a032e",
        translatedName: t("hooks.review6.city", "Sydney"),
        slug: "sydney",
      },
      feedback: t(
        "hooks.review6.feedback",
        "Just what I needed, was able to drop my bags off for a few hours and pick up late night due to their being open 24hrs.  Very convenient.",
      ),
      rating: 5,
      completedAt: mockCompletedAt(6),
      country: "Australia",
    }),
  ]
}
